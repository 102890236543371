export default {
  state: {
    distribution_point_uid: null,
    order_items: [],
  },

  getters: {
    getDistributionPointUid: (state) => state.distribution_point_uid,
    getOrderItems: (state) => state.order_items,
    // getOrder: (state) => state.order,
  },

  mutations: {
    setDistributionPointUid (state, data) {
      state.distribution_point_uid = data
    },  

    setOrderItems (state, data) {
      state.order_items = data
    },
  },

  actions: {
    setDistributionPointUid ({ commit }, data) {
      commit('setDistributionPointUid', data)
    },  

    setOrderItems ({ commit }, data) {
      commit('setOrderItems', data)
    },
  },
}