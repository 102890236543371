import Vue from 'vue'
import Vuex from 'vuex'
import apix from './apix'

import page from './modules/page'
import order from './modules/order'
import retail from './modules/retail'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    ...apix.state,
  },

  getters: {
    ...apix.getters,
  },

  actions: {
    ...apix.actions,
  },

  mutations: {
    ...apix.mutations,
  },

  modules: {
    order: {
      namespaced: true,
      ...order,
    },

    retail: {
      namespaced: true,
      ...retail
    },

    page: {
      namespaced: true,
      ...page,
    },
  },
});

export default store