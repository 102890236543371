<template>
  <v-card 
    flat
    color="transparent"
  > 
    <v-card-title>
      <div>
        <h1 class="title font-weight-bold grey--text text--darken-1">
          {{ title }}
        </h1>
        <app-crumbs 
          :crumbs="breadcrumbs" 
        />
      </div>
      
      <v-spacer></v-spacer>

      <slot name="actions">
        <v-btn
          v-if="showCallToAction"
          color="primary"
          class="ttn body-2 rounded-lg"
          @click="$emit('call-to-action')"
        >
          {{ callToActionText }}
        </v-btn>
      </slot>
    </v-card-title>
    
    <v-card-text>
      <slot name="content" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    breadcrumbs: {
      type: Array,
      required: true,
    },

    callToActionText: {
      type: String,
    },

    callToActionPermission: {
      type: String,
    },
  },

  computed: {
    showCallToAction () {
      return Boolean(this.callToActionText) && vault.can(this.callToActionPermission)
    }
  }
}
</script>