import { pick } from 'lodash'

export default {
  state: {
    page: {
      title: '',
      crumbs: [],
      actionText: '',
    },

    action: null
  },

  getters: {
    getPage: state => state.page,
    getAction: state => state.action,
  },

  actions: {
    setPage ({ commit }, data) {
      commit('setPage', data)
    },

    setAction ({ commit }, data) {
      commit('setAction', data)
    },
  },

  mutations: {
    setPage (state, data) {
      state.page = data
    },

    setAction (state, data) {
      state.action = data
    },
  },
}