import Vault from '@/libs/core/Vault'
import Vue from 'vue'
import VueRouter from 'vue-router'

const routes = [
  {
    path: '/auth',
    component: () => import('@/layouts/AuthLayout.vue'),
    beforeEnter: (to, from, next) => {
      const { token, user } = Vault.peek()
      if (!token) {
        if (user && to.name != 'login') {
          next('/auth/login')
        } else {
          next()
        }
      } else {
        next('/')
      }
    },
    children: [
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/RegisterPage.vue'),
      },

      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/LoginPage.vue'),
      }, 
    ]
  },

  // {
  //   path: '/auth',
  //   component: () => import('@/views/layouts/AuthLayout.vue'),
  //   beforeEnter: (to, from, next) => {
  //     const { token, user } = Vault.peek()
  //     if (!token) {
  //       if (user && to.name != 'login') {
  //         next('/auth/login')
  //       } else {
  //         next()
  //       }
  //     } else {
  //       next('/')
  //     }
  //   },
  //   children: [
  //     {
  //       path: 'register',
  //       name: 'register',
  //       component: () => import('@/views/pages/RegisterPage.vue'),
  //     },

  //     {
  //       path: 'login',
  //       name: 'login',
  //       component: () => import('@/views/pages/LoginPage.vue'),
  //     }, 
  //   ]
  // },

  {
    path: '',
    component: () => import('@/layouts/AppLayout.vue'),
    beforeEnter: (to, from, next) => {
      const { client, token } = Vault.peek()
      if (!client) {
        if (!token) {
          next('/auth/login')
        } else {
          next('/clients')
        }
      } else {
        next()
      }
    },
    children: [
      // {
      //   path: 'insurance/orders',
      //   name: 'insurance.orders.index',
      //   component: () => import('@/views/insurance/OrderPage.vue')
      // },

      {
        path: 'retail/orders',
        name: 'retail.orders.index',
        component: () => import('@/views/retail/OrderPage.vue')
      },
    ]
  },

  { path: '*', component: () => import('@/views/errors/404.vue') }
]

Vue.use(VueRouter)
const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
