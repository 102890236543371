const serviceRoutes = {
  iam: [
    { name: 'clients', url: 'clients' },
    { name: 'partnerships', url: 'partnerships' },
  ],

  insurance: [
    { name: 'orders', url: 'orders' },
    { name: 'productVersions', url: 'product-versions' },
    { name: 'referenceUnitCoefficients', url: 'reference-unit-coefficients' },
  ],
  
  nchi: [
    { name: 'jurisdictions', url: 'jurisdictions' },
  ],

  silo: [
    { name: 'farmResources', url: 'resources' },
  ],

  retail: [
    { name: 'prices', url: 'prices' },
    { name: 'distributionPoints', url: 'distribution-points' },
    { name: 'retailOrders', url: 'orders' },
  ],

  bidhaa: [
    { name: 'brands', url: 'brands' },
  ]
}

let routes = []
for (const service in serviceRoutes) {
  routes = routes.concat(
      serviceRoutes[service].map(({ name, url }) => ({
        name, 
        url: `${service}/${url}`
    }))
  )
}

export default routes